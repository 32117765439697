<template>
  <b-row class="match-height">
    <b-col
      md="12"
      lg="12"
    >
      <b-card no-body>
        <b-card-body>
          <b-card-title class="text-dashboard">Software Workflow</b-card-title>
        </b-card-body>
        <b-img
          fluid
          :src="require('@/assets/images/img/shutterstock-1896341410-v-03.png')"
          alt="Card image cap"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
  },
}
</script>
