<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <dashboard-software-workflow />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <dashboard-sales-radar-chart :data="data.salesChart" />
      </b-col>
      <b-col lg="8">
        <dashboard-line-area-chart :data="data.lineChart" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import DashboardSoftwareWorkflow from './DashboardSoftwareWorkflow.vue'
import DashboardSalesRadarChart from './DashboardSalesRadarChart.vue'
import DashboardLineAreaChart from './DashboardLineAreaChart.vue'

export default {
  components: {
    BRow,
    BCol,
    DashboardSoftwareWorkflow,
    DashboardSalesRadarChart,
    DashboardLineAreaChart,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
  methods: {
    kFormatter,
  },
}
</script>
